import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

export default function ShareLinks(props) {
  const { wrapperClassNames, iconWidth, shareTitle, url, titleText } = props;
  const { fbLogo, twitterLogo, linkedinLogo } = useStaticQuery(
    graphql`
      query {
        fbLogo: file(relativePath: { eq: "social/facebook.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        twitterLogo: file(relativePath: { eq: "social/twitter.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        linkedinLogo: file(relativePath: { eq: "social/linkedin.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        instaLogo: file(relativePath: { eq: "social/insta.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  );

  const fbLogoImage = getImage(fbLogo);
  const twitterLogoImage = getImage(twitterLogo);
  const linkedinLogoImage = getImage(linkedinLogo);

  return (
    <div className={wrapperClassNames}>
      <p className=' font-bold text-base uppercase'>{titleText}</p>

      <div className='article-social-links flex lg:mt-[16px]'>
        <SocialShareWrapper image={twitterLogoImage} iconWidth={iconWidth}>
          <TwitterShareButton
            url={url}
            quote={shareTitle}
            className='h-[100%] w-[100%]'
          />
        </SocialShareWrapper>
        <SocialShareWrapper
          classes={'ml-[20px]'}
          image={linkedinLogoImage}
          iconWidth={iconWidth}
        >
          <LinkedinShareButton
            url={url}
            quote={shareTitle}
            className='h-[100%] w-[100%]'
          />
        </SocialShareWrapper>
        <SocialShareWrapper
          classes={'ml-[20px]'}
          image={fbLogoImage}
          iconWidth={iconWidth}
        >
          <FacebookShareButton
            url={url}
            quote={shareTitle}
            className='h-[100%] w-[100%]'
          />
        </SocialShareWrapper>
      </div>
    </div>
  );
}

const SocialShareWrapper = (props) => {
  const { image, iconWidth, classes } = props;
  const bgImage = convertToBgImage(image);
  const maybeNotDefaultIconWidth = iconWidth || 20;
  return (
    <div className={'w-[' + maybeNotDefaultIconWidth + 'px] ' + classes}>
      <BackgroundImage
        style={{
          width: maybeNotDefaultIconWidth,
          height: maybeNotDefaultIconWidth,
        }}
        {...bgImage}
        className='cursor-pointer '
      >
        {props.children}
      </BackgroundImage>
    </div>
  );
};
