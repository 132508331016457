import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useEffect, useState, useRef } from 'react';

export default function ArticleHeading({
  articleTitle,
  articleCreatedDate,
  articleCoverImage,
}) {
  const [headingHeight, setHeadingHeight] = useState(null);
  const headingRef = useRef();
  // const image = getImage(articleCoverImage.gatsbyImageData);
  useEffect(() => {
    setHeadingHeight(headingRef.current.clientHeight);
  }, [headingRef]);

  // const coverImage = articleCoverImage[0];
  // const cdnFile = coverImage.cdn_files[0];

  return (
    <div ref={headingRef} className=' lg:mb-[60px]  mb-[16px]   pt-[16px]'>
      <h1 className='  text-[48px] font-bold  '>{articleTitle}</h1>
      <div className='py-[24px] '>
        <GatsbyImage
          image={articleCoverImage.gatsbyImageData}
          alt={articleCoverImage.fileName}
          layout={'fullWidth'}
        />
      </div>

      <p className='mt-[12px] text-gray-main  text-base lg:text-xl'>
        {articleCreatedDate}
      </p>
    </div>
  );
}
