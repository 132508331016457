import React from 'react';
import Layout from '../templates/layout';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import useScreenSize from 'use-screen-size';
import ArticleHeading from '../components/blog/heading';
import ShareLinks from '../components/blog/share_links';
import { GenerateContentByType } from '../components/blog/elements';
import { Link } from 'gatsby';

export default function Article(props) {
  const screenSize = useScreenSize();
  const { recomendedRandomPosts } = props.pageContext;
  const { title, date, content, description, slug, coverImage } =
    props.data.graphCmsPost;

  const postAssets = props.data.allGraphCmsAsset.nodes;
  const path = props.path;
  const url = 'https://careery.pro' + path;
  const createFullDate = new Date(date);
  const seo = {
    title: title,
    description: description,
    url: `/${slug}`,
    // image: postData.coverimage[0].cdn_files[0],
    titleTemplate: '',
  };
  const dateFormatter = new Intl.DateTimeFormat('en-GB', {
    month: 'long',
    day: 'numeric',
  });

  const formatedDate = dateFormatter.format(createFullDate);

  return (
    <Layout screenSize={screenSize} buttonType='apply'>
      <SEO params={seo} />
      <div className='mt-[125px] lg:mt-[150px]  px-[16px]'>
        <article className='mx-auto max-w-[750px] text-[#423c3b]'>
          <ArticleHeading
            articleCoverImage={coverImage}
            // articleCategory={postData.category ? postData.category : ''}
            articleTitle={title}
            articleCreatedDate={formatedDate}
          />

          {content.raw.children.map((content) => {
            return (
              <GenerateContentByType
                content={content}
                postAssets={postAssets}
              />
            );
          })}
          {['xs', 's', 'm'].includes(screenSize.screen) && (
            <ShareLinks
              wrapperClassNames={
                'flex justify-start space-x-[25px] items-center w-full py-[16px] px-[24px] bg-gray-light'
              }
              titleText={'Share: '}
              articleTitle={title}
              url={url}
            />
          )}
          <RecomendedPosts recomendedPosts={recomendedRandomPosts} />
        </article>
      </div>
    </Layout>
  );
  // return <div></div>;
}
const RecomendedPosts = ({ recomendedPosts }) => {
  return (
    <div className='flex flex-col space-y-[10px] pt-[20px] text-[18px]'>
      <p className='font-bold'>Read also:</p>
      {recomendedPosts.map((post) => {
        return (
          <Link to={`/blog/${post.slug}`} className='cursor-pointer'>
            <p className='underline underline-offset-1'>{post.title}</p>
          </Link>
        );
      })}
    </div>
  );
};

export const postQuery = graphql`
  query Article($id: String!, $handlers: [String!]) {
    graphCmsPost(id: { eq: $id }) {
      id
      title
      slug
      date
      description
      coverImage {
        gatsbyImageData(layout: FULL_WIDTH, quality: 25)
        id
        fileName
      }
      content {
        raw
      }
      tags
    }
    allGraphCmsAsset(filter: { handle: { in: $handlers } }) {
      nodes {
        handle
        gatsbyImageData(layout: FULL_WIDTH, quality: 25)
      }
    }
  }
`;
