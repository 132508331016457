import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const paragraphStyle =
  'mb-[24px] tracking-wide text-[#3c3c3b] text-[20px]  leading-9 ';

const GenerateContentByType = ({ content, postAssets }) => {
  const contentType = content.type;

  if (contentType === 'block-quote') {
    return <Quote content={content} />;
  }

  if (contentType.includes('heading')) {
    return <Heading level={contentType} content={content} />;
  }

  if (contentType === 'paragraph') {
    return <Paragraph content={content} />;
  }

  if (contentType === 'numbered-list') {
    return <List content={content} typeOfList='numerical' />;
  }

  if (contentType === 'bulleted-list') {
    return <List content={content} typeOfList='bullet' />;
  }

  if (contentType === 'table') {
    return <Table content={content} />;
  }

  if (contentType === 'image') {
    return <Image content={content} postAssets={postAssets} />;
  }

  return <div></div>;
};

const Image = ({ content, postAssets }) => {
  const imgHandle = content.handle;
  const findGatsbyImage = postAssets.find(
    (asset) => asset.handle === imgHandle
  );

  const gatsbyImageData = findGatsbyImage.gatsbyImageData;

  // const image = getImage(gatsbyImageData);

  return (
    <GatsbyImage
      className={'mb-[24px]'}
      image={gatsbyImageData}
      alt={content.title}
    />
  );
};
const Quote = ({ content }) => {
  return (
    <div className='article-ul-w-title-wrapper my-[32px]  px-[16px] lg:px-0 '>
      <div
        className={`pl-[32px] article-ul-title-wrapper italic ${paragraphStyle}`}
      >
        {defstructContentStyles(content.children)}
      </div>
    </div>
  );
};

const Table = ({ content }) => {
  const rows = content.children.map(({ children }) => {
    return children.map(({ children }, rowIndex) => {
      const row = children.map(({ children }) => {
        if (rowIndex === 0) {
          return (
            <th className={'border bold p-4'}>
              {children.map((content) => (
                <GenerateContentByType content={content} />
              ))}
            </th>
          );
        }
        return (
          <td className={'border p-4'}>
            {children.map((content) => (
              <GenerateContentByType content={content} />
            ))}
          </td>
        );
      });
      return <tr>{row}</tr>;
    });
  });
  return (
    <div className='overflow-x-scroll'>
      <table className=''>{rows}</table>
    </div>
  );
};
const Paragraph = ({ content }) => {
  return (
    <p className={`${paragraphStyle}`}>
      {defstructContentStyles(content.children)}
    </p>
  );
};

const Heading = ({ id, level, content }) => {
  const defstructedContent = defstructContentStyles(content.children);
  const singleHeadingStyle = 'text-[28px] font-bold py-[24px] text-[#3c3c3b] ';
  if (level === 'heading-one') {
    return (
      <h1 id={id} className={singleHeadingStyle}>
        {defstructedContent}
      </h1>
    );
  }

  if (level === 'heading-two') {
    return (
      <h2 id={id} className={singleHeadingStyle}>
        {defstructedContent}
      </h2>
    );
  }

  if (level === 'heading-three') {
    return (
      <h3 id={id} className={singleHeadingStyle}>
        {defstructedContent}
      </h3>
    );
  }

  if (level === 'heading-four') {
    return (
      <h4 id={id} className={singleHeadingStyle}>
        {defstructedContent}
      </h4>
    );
  }

  if (level === 'heading-five') {
    return (
      <h5 id={id} className={singleHeadingStyle}>
        {defstructedContent}
      </h5>
    );
  }

  if (level === 'heading-six') {
    return (
      <h6 id={id} className={singleHeadingStyle}>
        {defstructedContent}
      </h6>
    );
  }
  console.error('Wrong Heading defination');
  return <div> {defstructedContent}</div>;
};

const List = ({ content, typeOfList }) => {
  const listElemets = content.children.map((item) => {
    const elementOfList = item.children.map((el) =>
      defstructContentStyles(el.children)
    );
    return <li className={paragraphStyle}>{elementOfList} </li>;
  });

  if (typeOfList === 'numerical') {
    return <ol>{listElemets}</ol>;
  }
  return <ul>{listElemets}</ul>;
};

const defstructContentStyles = (contentChild) => {
  const content = contentChild.map((text) => {
    if (text.href) {
      const href = text.href;
      return text.children.map((text) => (
        <a rel='nofollow' href={href} className={generateTailwindStyles(text)}>
          {formatString(text.text)}
        </a>
      ));
    }

    return (
      <span className={generateTailwindStyles(text)}>
        {formatString(text.text)}
      </span>
    );
  });
  // generateTailwindStyles(text)
  return content;
};

const formatString = (string) => {
  return string ? string.replace(/ +(?= )/g, '') : undefined;
};
const generateTailwindStyles = (text) => {
  const styledKeys = Object.entries(text)
    .map(([k, v]) => {
      if (v === true) {
        return k;
      }
    })
    // only truth
    .filter((x) => x);

  const translateToTailwind = styledKeys.map((key) => {
    return key === 'italic'
      ? 'italic'
      : key === 'underline'
      ? 'underline underline-offset-1'
      : key === 'bold'
      ? 'font-bold'
      : '';
  });

  const styledString = translateToTailwind.join(' ');

  return styledString;
};

export { Quote, Paragraph, Heading, List, Table, GenerateContentByType };
